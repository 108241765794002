import { logWarn } from 'logging';

/**
 * This function calculates if an event should be to send to sentry
 * or if we want to exclude it.
 *
 * @param {Object} event Sentry event
 * @param {Object} eventMatcher Matcher settings
 * @param {string} [eventMatcher.type] The event type. For example: TypeError
 * @param {string} [eventMatcher.value] The event message. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.message] The event message in case is not an exception. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.file] The source file path or string. For example: http://bentobox.com/file.js or just file.js
 * @param {string} [eventMatcher.missingStackTrace] If set to true, the expectation is that the event doesn't have a stack trace, if set to false (the default), the event must have a stacktrace.
 *
 * @returns boolean
 */
export function excludeEvent(event, eventMatcher) {
  // Usage restrictions
  const matcherProperties = Object.getOwnPropertyNames(eventMatcher);
  if (
    matcherProperties.includes('file') &&
    matcherProperties.includes('missingStackTrace')
  ) {
    throw Error(
      'This two properties can not be set at the same time, please only use one: "file", "missingStackTrace"'
    );
  }

  try {
    if (event.exception) {
      let typeAndValueMatches = false;
      let fileMatches = false;
      let passesStackTraceCheck = true;

      const sentryExcept =
        event.exception.values[event.exception.values.length - 1];

      if (eventMatcher.missingStackTrace && sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (!eventMatcher.missingStackTrace && !sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (eventMatcher.type && eventMatcher.value) {
        const typeMatches = sentryExcept.type.includes(eventMatcher.type);
        const valueMatches = sentryExcept.value.includes(eventMatcher.value);
        typeAndValueMatches = typeMatches && valueMatches;
      }

      if (eventMatcher.file) {
        const frame =
          sentryExcept.stacktrace?.frames[
            sentryExcept.stacktrace?.frames.length - 1
          ];
        if (frame) {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.file
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.file
          );
          fileMatches = filenameMatches || absPathMatches;
        }
        return typeAndValueMatches && fileMatches;
      }

      return typeAndValueMatches && passesStackTraceCheck;
    } else {
      if (eventMatcher.message && event.message) {
        return event.message.includes(eventMatcher.message);
      }
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function beforeSend(event) {
  const ignoreEvent = excludeEventMatcherList.some(matcher =>
    excludeEvent(event, matcher)
  );

  if (ignoreEvent) {
    // Extract exception details or fallback to a message
    const sentryExcept = event.exception?.values?.at(-1) || {
      type: 'Message',
      value: event.message || 'Unknown exception'
    };

    // Log the exception for debugging
    logWarn(
      `[Online-Ordering] Unhandled Exception: [${sentryExcept.type ||
        'Unknown Type'}][${sentryExcept.value || 'Unknown Value'}]`,
      { eventId: event.event_id, tags: event.tags }
    );

    // Return null to ignore the event
    return null;
  }
  return event;
}

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  {
    // https://bentobox.sentry.io/issues/2460602068/
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: undefined',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/2460602068/
    type: 'UnhandledRejection',
    value:
      'Non-Error promise rejection captured with value: Object Not Found Matching',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/4055282825/
    type: 'TypeError',
    value: "undefined is not an object (evaluating 'n.length')"
  },
  {
    // https://bentobox.sentry.io/issues/6065204059/
    type: 'TypeError',
    value: "undefined is not an object (evaluating 't.ssresp')",
    file: 'origin-secure-prod-radware.getbento.com'
  },
  {
    // https://bentobox.sentry.io/issues/5940192574/events/2fbe4b9765744d108d097f9225a20241/
    message:
      "Cannot read properties of undefined (reading 'catering_fulfillment_method') [object Object] TypeError: Cannot read properties of undefined (reading 'catering_fulfillment_method')"
  },
  {
    // https://bentobox.sentry.io/issues/5940192574/events/565676696d094629b8d954b6bbee97c3/
    message:
      "Cannot read properties of undefined (reading 'fulfillment_date') [object Object] TypeError: Cannot read properties of undefined (reading 'fulfillment_date')"
  },
  {
    // https://bentobox.sentry.io/issues/5901727704/
    message: 'Catering Cart with slug None could not be found'
  },
  {
    // https://bentobox.sentry.io/issues/3896228121/
    type: 'Error',
    value: 'Network Error'
  },
  {
    // https://bentobox.sentry.io/issues/1581838644/?project=1513815
    type: 'Error',
    value: 'Could not load "places_impl"',
    file: 'maps.googleapis.com'
  },
  {
    // https://bentobox.sentry.io/issues/6114229725/?project=1513815
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'planRemediations')",
    file: 'wsv3cdn.audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/2460602068/?project=1513815
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: OVER_QUERY_LIMIT',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/5905492014/?project=1513815
    message:
      'Cannot convert undefined or null to object [object Object] TypeError: Cannot convert undefined or null to object'
  },
  {
    // https://bentobox.sentry.io/issues/3749940569/events/3c3aff46c5e746838e0671278b104a1e/
    type: 'TypeError',
    value:
      "Cannot read properties of undefined (reading 'catering_fulfillment_method')",
    file: 'useAppBarData.js'
  },
  {
    // https://bentobox.sentry.io/issues/3749670141/events/d5ef04f8cdd241e2a2bc24a68573dafc/
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'pickup')",
    file: 'MenuScreen.js'
  },
  {
    // https://bentobox.sentry.io/issues/5513327317/events/40899d9d5eca436f8c18b081fde9dc06/
    type: 'TypeError',
    value: 'Cannot convert object to primitive value'
  },
  {
    // https://bentobox.sentry.io/issues/5930401462/events/0f22f97633554f9b8a6694b6b106ed9e/
    message:
      "Cannot read properties of undefined (reading 'map') [object Object] TypeError: Cannot read properties of undefined (reading 'map')"
  },
  {
    // https://bentobox.sentry.io/issues/6206011442/events/20fd86b489614c708f77bc47a3e9e4f1/
    type: 'ReferenceError',
    value: "Can't find variable: HTMLDialogElement",
    file: 'maps.googleapis.com'
  },
  {
    // https://bentobox.sentry.io/issues/6240508620
    type: 'Error',
    value: 'Location information not found from useLocationInformation',
    file: 'useLocationInformation.js'
  },
  {
    // https://bentobox.sentry.io/issues/2050388791
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'toString')",
    file: 'generatePath.js'
  },
  {
    // https://bentobox.sentry.io/issues/3382298758
    type: 'TypeError',
    value: 'Cannot read properties of undefined',
    abs_path: 'node_modules/@sentry/browser/esm/helpers.js'
  },
  {
    // https://bentobox.sentry.io/issues/3382298758
    type: 'TypeError',
    value: 'null is not an object',
    abs_path: 'node_modules/@sentry/browser/esm/helpers.js'
  },
  {
    // https://bentobox.sentry.io/issues/3382298758
    type: 'TypeError',
    value: 'undefined is not an object',
    abs_path: 'node_modules/@sentry/browser/esm/helpers.js'
  },
  {
    // https://bentobox.sentry.io/issues/3382298758
    type: 'TypeError',
    value: 'a2z.onStatusUpdate is not a function',
    abs_path: 'node_modules/@sentry/browser/esm/helpers.js'
  },
  {
    // https://bentobox.sentry.io/issues/4265574363
    type: 'Error',
    value: 'ResizeObserver loop completed with undelivered notifications'
  },
  {
    // https://bentobox.sentry.io/issues/1580075943/?project=1513815
    type: 'Error',
    value: 'Could not load',
    file: 'maps.googleapis.com'
  },
  {
    type: 'Error',
    value:
      'Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.',
    filename: 'stormcaster.js'
  },
  {
    // https://bentobox.sentry.io/issues/2460602068
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: OVER_QUERY_LIMIT',
    message:
      'Geocoding Service: You have exceeded your rate-limit for this API.'
  },
  {
    // https://bentobox.sentry.io/issues/6186557418/
    // https://bentobox.sentry.io/issues/6176770104/
    // https://bentobox.sentry.io/issues/6186557415/
    // https://bentobox.sentry.io/issues/6186557382/
    // https://bentobox.sentry.io/issues/6186557387/
    // https://bentobox.sentry.io/issues/6186557380/
    // https://bentobox.sentry.io/issues/6186557427/
    type: 'TypeError',
    value: "Cannot read property 'setScreenSize' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'setCurrentPosition' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'audioVolumeChange' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'setMaxSize' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'fireChangeEvent' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'fireEvent' of undefined",
    file: '\u003Canonymous\u003E'
  },
  {
    type: 'TypeError',
    value: "Cannot read property 'setDefaultPosition' of undefined",
    file: '\u003Canonymous\u003E'
  },

  {
    type: 'ReferenceError',
    value: "Can't find variable: HTMLDialogElement",
    file: 'maps.googleapis.com'
  },
  {
    type: 'TypeError',
    value: 'G.at is not a function'
  }
];
