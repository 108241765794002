import { fontFaceStyle } from '@getbento/website-components';

import { FONT_FORMATS, BASE_APPLICATION_FONT_URL } from 'constants/fonts';

const defaultFontFaceStyle = (
  name,
  src,
  fontWeight = 'normal',
  fontStyle = 'normal'
) => {
  const sources = [
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.eot?#iefix`,
      format: FONT_FORMATS.EMBEDDED_OPENTYPE
    },
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.otf`,
      format: FONT_FORMATS.OPENTYPE
    },
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.woff`,
      format: FONT_FORMATS.WOFF
    },
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.woff2`,
      format: FONT_FORMATS.WOFF2
    },
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.ttf`,
      format: FONT_FORMATS.TRUETYPE
    },
    {
      url: `${BASE_APPLICATION_FONT_URL}${src}.svg#${name}`,
      format: FONT_FORMATS.SVG
    }
  ];

  return fontFaceStyle(name, sources, fontWeight, fontStyle);
};

// TODO: To deprecate as we'll return back to assigning fontFamily instead of weight
const fontWeights = {
  regular: 400,
  italic: 300,
  bold: 700,
  medium: 500
};

// TODO: To deprecate since we'll be assigning font family on a component basis
export const fontFamily = 'CircularStd';

const BASE_MEDIA_CDN = '//media-cdn.getbento.com/';

/**
 * This function generates font-face strings for self-hosted fonts
 * that will be loaded into the MUI theme.
 *
 * @param {object[]} selfHostedFonts
 * @returns {string[]}
 */
export function loadSelfHostedCssFontFaces(selfHostedFonts) {
  if (!selfHostedFonts || !selfHostedFonts.length) {
    return [];
  }

  return selfHostedFonts.map(
    ({
      selfhosted_name: fontName,
      selfhosted_style: fontStyle,
      selfhosted_weight: fontWeight,
      selfhosted_eot: eot,
      selfhosted_woff2: woff2,
      selfhosted_woff: woff,
      selfhosted_ttf: ttf,
      selfhosted_otf: otf
    }) => {
      const sources = [];

      if (eot) {
        sources.push({
          url: `${BASE_MEDIA_CDN}${eot}#iefix`,
          format: FONT_FORMATS.EMBEDDED_OPENTYPE
        });
      }

      if (woff2) {
        sources.push({
          url: `${BASE_MEDIA_CDN}${woff2}`,
          format: FONT_FORMATS.WOFF2
        });
      }

      if (woff) {
        sources.push({
          url: `${BASE_MEDIA_CDN}${woff}`,
          format: FONT_FORMATS.WOFF
        });
      }

      if (ttf) {
        sources.push({
          url: `${BASE_MEDIA_CDN}${ttf}`,
          format: FONT_FORMATS.TRUETYPE
        });
      }

      if (otf) {
        sources.push({
          url: `${BASE_MEDIA_CDN}${otf}`,
          format: FONT_FORMATS.OPENTYPE
        });
      }

      return fontFaceStyle(
        fontName,
        sources,
        fontWeight ?? '400',
        fontStyle ?? 'normal'
      );
    }
  );
}

/**
 * This function creates a string of default font faces.
 *
 * @returns {string[]}
 */
export const loadDefaultFonts = () => [
  defaultFontFaceStyle('CircularStd-Book', 'CircularStd-Book', '400'),
  defaultFontFaceStyle('CircularStd-Medium', 'CircularStd-Medium', '500'),
  defaultFontFaceStyle('CircularStd-Bold', 'CircularStd-Bold', '700'),
  defaultFontFaceStyle(
    'CircularStd-Book',
    'CircularStd-BookItalic',
    '300',
    'italic'
  )
];

export default fontWeights;
